/* General Styles */
html * {
  font-family: "Avenir", sans-serif;
}

body {
  background-color: #ffffff;
  color: #333333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

/* Links */
a {
  color: #555;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  text-decoration: underline;
  color: #333;
}

/* Buttons */
.ui.primary.button {
  background-color: #f0f0f0;
  color: #333;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  border: 1px solid #ccc;
}

.ui.primary.button:hover,
.ui.primary.button:focus {
  background-color: #e0e0e0;
}

/* Grid Layout */
.ui.grid {
  margin: unset;
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: #222;
}

p {
  white-space: pre-line;
}

/* Images */
img {
  object-fit: cover;
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Root */
#root {
  width: 100%;
  margin: auto;
}

/* Navigation */
.ui.menu {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}

.ui.menu .item {
  padding: 1em 1.5em;
  color: #333;
}

/* Forms and Inputs */
input,
select,
textarea {
  border: 1px solid #ccc;
  padding: 0.5em;
  border-radius: 4px;
  width: 100%;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #999;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Footer */
footer {
  background-color: #f5f5f5;
  color: #777;
  padding: 2em 0;
  text-align: center;
}

footer a {
  color: #555;
}

footer a:hover {
  color: #333;
}

/* Modals */

.slide-pane__overlay {
  z-index: 1000;
}
.slide-pane {
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 100px;
  max-width: 75%;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s;
  will-change: transform;
}

.slide-pane:focus {
  outline-style: none;
}

.slide-pane_from_right {
  margin-left: auto;
  transform: translateX(100%);
}

.slide-pane_from_right.ReactModal__Content--after-open {
  transform: translateX(0%);
}

.slide-pane_from_right.ReactModal__Content--before-close {
  transform: translateX(100%);
}

.slide-pane_from_left {
  margin-right: auto;
  transform: translateX(-100%);
}

.slide-pane_from_left.ReactModal__Content--after-open {
  transform: translateX(0%);
}

.slide-pane_from_left.ReactModal__Content--before-close {
  transform: translateX(-100%);
}

.slide-pane_from_bottom {
  height: 90vh;
  margin-top: 10vh;
  transform: translateY(100%);
}

.slide-pane_from_bottom.ReactModal__Content--after-open {
  transform: translateY(0%);
}

.slide-pane_from_bottom.ReactModal__Content--before-close {
  transform: translateY(100%);
}

.slide-pane__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0);
}

.slide-pane__overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s;
  z-index: 1000;
}

.slide-pane__overlay.ReactModal__Overlay--before-close {
  background-color: rgba(0, 0, 0, 0);
}

.slide-pane__header {
  align-items: center;
  background-color: white;
  height: 10em;
  padding-top: 16px;
  flex: unset;
}

.slide-pane .slide-pane__title {
  font-weight: normal;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}

.slide-pane__content {
  height: 90%;
}

.slide-pane__subtitle {
  font-size: 1rem;
  margin-top: 2px;
}

/* Comments */
.ui.comments {
  max-width: 100%;
  white-space: pre-line;
}

/* Filters */
.filter-item {
  background-color: #e0e1e2;
  margin-right: 1em;
  border-radius: 6px;
  display: inline-block;
}

.filter-item select {
  border-color: #e0e1e2;
  border-bottom-width: 1px;
}

.filter-item .label {
  vertical-align: middle;
  padding-top: 0.9em;
}

/* Slick Carousel */
.slick-prev:before,
.slick-next:before {
  color: black !important;
}

/* Day Picker Calendar */
.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;
  flex-direction: row;
  padding-bottom: 1em;
  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--selected {
  background-color: #4a90e2;
  color: #f0f8ff;
}

.DayPicker-Day--selected:hover {
  background-color: #51a0fa;
}

/* Enhancements */
.foot-note {
  display: inline-block;
  font-weight: normal;
  font-style: italic;
  color: #888;
  font-size: 90%;
  margin-top: 0.4em;
}

/* Responsive Design */
@media (max-width: 768px) {
  .ui.grid {
    grid-template-columns: 1fr;
  }

  .slide-pane {
    max-width: 100%;
  }

  .ui.menu .item {
    padding: 0.5em;
  }
}
